import React, { useState, useEffect } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from "react-router-dom"
import Notification from '../Notification'
import { LinkContainer } from 'react-router-bootstrap'

const TroyOunceToGram = () => {
    const [ounces, setOunces] = useState('32.1505')
    const [grams, setGrams] = useState('1000')
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        handleChange(ounces)
        document.title = 'Troy-Unssi-Gramma-muunnin - Laske & Muunna'
    })

    const handleChange = (value) => {
        value = value.toString()
        value = value.replace(/,/g, '.')
        value = value.replace(/^(-)|[^0-9.,]+/g, '$1')
        value = value.replace(/.*?(-?)(\d*\.\d+)[^\d.]*/, '$1$2')
        const output = value.split('.')
        value = output.shift() + (output.length ? '.' + output.join('') : '')
        value = value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '')
        if (value === '-.') {
            value = '-'
        }
        if (value === '.') {
            value = ''
        }
        setOunces(value)
        setGrams((ounces * 31.103715338797).toFixed(10).replace(/\.?0+$/, '') + ' g')
        if (isNaN(value)) {
            if (value === '-') {
                setMessage(`0 Troy-unssia on grammoina 0 g`)
                setGrams('0 g')
            } else {
                setErrorMessage('Virheellinen luku. Syötä ainoastaan numeroita.')
                setMessage('')
            }
        } else {
            if (value.length > 0) {
                setMessage(`${ounces.replace('.', ',')} Troy-unssia on grammoina ${grams.replace('.', ',')}`)
                setErrorMessage('')
            } else {
                setMessage('')
                setErrorMessage('')
            }
        }

    }

    return (
        <div>
            <Breadcrumb>
                <LinkContainer to="../">
                    <Breadcrumb.Item>
                        Alkuun
                    </Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="./">
                    <Breadcrumb.Item>
                        Yksikkömuuntimet
                    </Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>
                    Troy-Unssi-Gramma-muunnin
                </Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div style={{ maxWidth: '90%' }}>
                    <h2>
                    Troy-Unssi-Gramma-muunnin
                    </h2>
                </div>
                <div className='calculator'>
                <p>Tällä muuntimella voit muuntaa Troy-unssit grammoiksi.</p>
                    <p>Troy-unssi on nykyään erityisesti jalometallien kuten kullan ja hopean mittaamiseen käytettävä massan yksikkö.
                        Troy-unssista käytetään usein myös nimitystä kultaunssi tai hopeaunssi.
                        Troy-unssi vastaa 31,1034768 grammaa ja 12 troy-unssia = 1 troy-pauna tai troy-naula.
                    </p>
                    <p>Muunna Troy-unssit grammoiksi helposti syöttämällä muuntimeen Troy-unssien määrä ja muunnin muuttaa Troy-unssit grammoiksi.</p>
                    <div>
                        <form>
                            <p>
                                <b>Syötä Troy-unssien määrä (t oz):</b> <input value={ounces.replace('.', ',')} onChange={({ target }) => handleChange(target.value)}>
                                </input>
                            </p>
                        </form>
                        <Notification message={message} result={grams.replace('.', ',')} errorMessage={errorMessage} />
                        {(ounces && grams) && <p><b>{ounces === '-' ? 0 : ounces.replace('.', ',')} t oz x 31,103715338797 = <span style={{ fontSize: '1.2em', color: '#155724' }}>{((ounces === '-' ? 0 : ounces) * 31.103715338797).toFixed(10).replace(/\.?0+$/, '').replace('.', ',')} g</span></b></p>}
                        <p>Katso myös <Link to="./gramma-troy-unssi-muunnin">Gramma-Troy-unssi-muunnin</Link>.</p>
                    </div>
                </div>
            </div>
            <hr />
            <h4>Troy-unssien muuttaminen grammoiksi</h4>
            <p>Troy-unssien muuttaminen grammoiksi onnistuu yksinkertaisella kaavalla: <b>Grammat = Troy-unssit x 31,103715338797</b>.
                <br />
                <br />
                Esimerkkejä Troy-unssi-Gramma muunnoksista:
            </p>
            <ul>
                <li>10 t oz = 10 x 31,103715338797 = 311,04 g</li>
                <li>32,15 t oz = 32,15 t oz x 31,103715338797 = 1000 g</li>
                <li>70 t oz = 70 x 31,103715338797 = 2177.26 g</li>
                <li>100 t oz = 100 x 31,103715338797 = 3110,37 g</li>
                <li>150 t oz = 150 x 31,103715338797 = 4665.56 g</li>
            </ul>
        </div>
    )
}

export default TroyOunceToGram