import React, { Suspense, lazy } from 'react'
import './bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import GramToOunce from './components/Conversion/GramToOunce'
import GramToTroyOunce from './components/Conversion/GramToTroyOunce'
import TroyOunceToGram from './components/Conversion/TroyOunceToGram'
import WeightCon from './components/Conversion/WeightCon'
import Footer from './components/Footer'
// import ReactGA from "react-ga4"
import Header from './components/Header'
//const Header = lazy(() => import('./components/Header'))
import Sidebar from './components/Sidebar'
import ScrollToTop from './ScrollToTop'
import { width } from '@fortawesome/free-regular-svg-icons/faAddressBook'
//import SuspenseFallback from './components/SuspenseFallback'
//import UrlNotFound from './components/UrlNotFound'
//const Sidebar = lazy(() => import('./components/Sidebar'))
const BinToDecCon = lazy(() => import('./components/Conversion/BinToDecCon'))
const DecToBinCon = lazy(() => import('./components/Conversion/DecToBinCon'))
const DecToHexCon = lazy(() => import('./components/Conversion/DecToHexCon'))
const DecToOctCon = lazy(() => import('./components/Conversion/DecToOctCon'))
const PercDecCon = lazy(() => import('./components/Conversion/PercDecCon'))
const DecPercCon = lazy(() => import('./components/Conversion/DecPercCon'))
const FreqCon = lazy(() => import('./components/Conversion/FreqCon'))
const Useful = lazy(() => import('./components/Useful/Useful'))
const CharCount = lazy(() => import('./components/Useful/CharCount'))
const ElecGasCar = lazy(() => import('./components/Useful/ElecGasCar'))
const VatCount = lazy(() => import('./components/Finance/VatCount'))
const BMICalc = lazy(() => import('./components/Health/BMICalc'))
const Home = lazy(() => import('./components/Home'))
//const Footer = lazy(() => import('./components/Footer'))
const Finance = lazy(() => import('./components/Finance/Finance'))
const ExchangeRates = lazy(() => import('./components/Finance/ExchangeRates'))
const Health = lazy(() => import('./components/Health/Health'))
const Conversion = lazy(() => import('./components/Conversion/Conversion'))
const LengthCon = lazy(() => import('./components/Conversion/LengthCon'))
const Math = lazy(() => import('./components/Math/Math'))
const PercentCalc = lazy(() => import('./components/Math/PercentCalc'))
const AverageCalc = lazy(() => import('./components/Math/AverageCalc'))
const ShoeSizeCon = lazy(() => import('./components/Conversion/ShoeSizeCon'))
const BraSizeCon = lazy(() => import('./components/Conversion/BraSizeCon'))
const CelcFahrCon = lazy(() => import('./components/Conversion/CelcFahrCon'))
const FahrCelcCon = lazy(() => import('./components/Conversion/FahrCelcCon'))
const Multiplication = lazy(() => import('./components/Math/Multiplication'))
const AdditionTable = lazy(() => import('./components/Math/AdditionTable'))
const ContactForm = lazy(() => import('./components/ContactForm'))
const UrlNotFound = lazy(() => import('./components/UrlNotFound'))
//const ScrollToTop = lazy(() => import('./ScrollToTop'))
const CelsKelvinCon = lazy(() => import('./components/Conversion/CelsKelvinCon'))
const KelCelsCon = lazy(() => import('./components/Conversion/KelCelsCon'))
const KelvinFahrCon = lazy(() => import('./components/Conversion/KelvinFahrCon'))
const FahrKelvinCon = lazy(() => import('./components/Conversion/FahrKelvinCon'))
const TemperatureCon = lazy(() => import('./components/Conversion/TemperatureCon'))
const OunceToGram = lazy(() => import('./components/Conversion/OunceToGram'))
const WebDesign = lazy(() => import('./components/WebDesign/WebDesign'))
const HTMLColorPicker = lazy(() => import('./components/WebDesign/HtmlColorPicker'))
/* const RouteChangeTracker = lazy(() => import('./components/RouteChangeTracker'))
const TRACKING_ID = "G-9K8CQZF8J6" */

function App() {
  const containerStyle = {
    backgroundColor: '#fafafa'
  }

  const placeholderStyle = {
    width: '100%',
    height: '100vh'
  }

  // ReactGA.initialize(TRACKING_ID)

  return (
    <Container style={containerStyle} className="mb-5">
        <Router>
          <Row className="mb-5" style={{ marginBottom: '1rem !important' }}>
            <Col lg={true}>
              <Header />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8}>
              <ScrollToTop>
              <Suspense fallback={<div style={placeholderStyle}><Spinner animation="grow" role="status"></Spinner></div>}>
                <Switch>
                  <Route exact path="/" render={() => <Home />} />
                  <Route exact path="/matematiikka" render={() => <Math />} />
                  <Route exact path="/matematiikka/prosenttilaskuri" render={() => <PercentCalc />} />
                  <Route exact path="/matematiikka/kertotaulu" render={() => <Multiplication />} />
                  <Route exact path="/matematiikka/yhteenlasku" render={() => <AdditionTable />} />
                  <Route exact path="/matematiikka/keskiarvolaskuri" render={() => <AverageCalc />} />
                  <Route exact path="/yksikkomuuntimet/binaari-desimaali-muunnin" render={() => <BinToDecCon />} />
                  <Route exact path="/yksikkomuuntimet/desimaali-binaari-muunnin" render={() => <DecToBinCon />} />
                  <Route exact path="/yksikkomuuntimet/desimaali-heksadesimaali-muunnin" render={() => <DecToHexCon />} />
                  <Route exact path="/yksikkomuuntimet/desimaali-oktaaliluku-muunnin" render={() => <DecToOctCon />} />
                  <Route exact path="/yksikkomuuntimet/prosentti-desimaalimuunnin" render={() => <PercDecCon />} />
                  <Route exact path="/yksikkomuuntimet/desimaali-prosenttilukumuunnin" render={() => <DecPercCon />} />
                  <Route exact path="/yksikkomuuntimet/kengankoko-muunnin" render={() => <ShoeSizeCon />} />
                  <Route exact path="/yksikkomuuntimet/rintaliivienkoko-muunnin" render={() => <BraSizeCon />} />
                  <Route exact path="/yksikkomuuntimet/celsius-fahrenheit-muunnin" render={() => <CelcFahrCon />} />
                  <Route exact path="/yksikkomuuntimet/celsius-kelvin-muunnin" render={() => <CelsKelvinCon />} />
                  <Route exact path="/yksikkomuuntimet/kelvin-celsius-muunnin" render={() => <KelCelsCon />} />
                  <Route exact path="/yksikkomuuntimet/kelvin-fahrenheit-muunnin" render={() => <KelvinFahrCon />} />
                  <Route exact path="/yksikkomuuntimet/fahrenheit-kelvin-muunnin" render={() => <FahrKelvinCon />} />
                  <Route exact path="/yksikkomuuntimet/fahrenheit-celsius-muunnin" render={() => <FahrCelcCon />} />
                  <Route exact path="/yksikkomuuntimet/lampotilamuunnin" render={() => <TemperatureCon />} />
                  <Route exact path="/yksikkomuuntimet/unssi-gramma-muunnin" render={() => <OunceToGram />} />
                  <Route exact path="/yksikkomuuntimet/gramma-unssi-muunnin" render={() => <GramToOunce />} />
                  <Route exact path="/yksikkomuuntimet/gramma-troy-unssi-muunnin" render={() => <GramToTroyOunce />} />
                  <Route exact path="/yksikkomuuntimet/troy-unssi-gramma-muunnin" render={() => <TroyOunceToGram />} />
                  <Route exact path="/yksikkomuuntimet/painomuunnin" render={() => <WeightCon />} />
                  <Route exact path="/yksikkomuuntimet/taajuusmuunnin" render={() => <FreqCon />} />
                  <Route exact path="/hyoty" render={() => <Useful />} />
                  <Route exact path="/hyoty/merkki-ja-sanalaskuri" render={() => <CharCount />} />
                  <Route exact path="/hyoty/sahkoauto-polttomoottori-hintavertailu" render={() => <ElecGasCar />} />
                  <Route exact path="/raha" render={() => <Finance />} />
                  <Route exact path="/raha/alv-laskuri" render={() => <VatCount />} />
                  <Route exact path="/raha/valuuttalaskuri" render={() => <ExchangeRates />} />
                  <Route exact path="/terveys" render={() => <Health />} />
                  <Route exact path="/terveys/painoindeksilaskuri" render={() => <BMICalc />} />
                  <Route exact path="/yksikkomuuntimet" render={() => <Conversion />} />
                  <Route exact path="/yksikkomuuntimet/pituusmuunnin" render={() => <LengthCon />} />
                  <Route exact path="/web-ja-ohjelmointi" render={() => <WebDesign />} />
                  <Route exact path="/web-ja-ohjelmointi/html-varinvalitsin" render={() => <HTMLColorPicker />} />
                  <Route exact path="/ota-yhteytta" render={() => <ContactForm />} />
                  <Route component={UrlNotFound} />
                </Switch>
                </Suspense>
              </ScrollToTop>
            </Col>
            <Col xs={12} md={4}>
              <Sidebar />
            </Col>
          </Row>
          <hr />
          <hr />
          <p style={{ marginTop: '20px' }}>Löysitkö virheen sivustolta? Haluatko antaa palautetta? <Link to="/ota-yhteytta">Ota yhteyttä meihin tästä</Link>.</p>
          <Footer />
          {/* <RouteChangeTracker /> */}
        </Router>
    </Container>
  )
}

export default App
