import React, { useState, useEffect } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from "react-router-dom"
import Notification from '../Notification'
import { LinkContainer } from 'react-router-bootstrap'

const GramToTroyOunce = () => {
    const [ounces, setOunces] = useState('3.21507')
    const [grams, setGrams] = useState('100')
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        handleChange(grams)
        document.title = 'Gramma-Troy-Unssi-muunnin - Laske & Muunna'
    })

    const handleChange = (value) => {
        value = value.toString()
        value = value.replace(/,/g, '.')
        value = value.replace(/^(-)|[^0-9.,]+/g, '$1')
        value = value.replace(/.*?(-?)(\d*\.\d+)[^\d.]*/, '$1$2')
        const output = value.split('.')
        value = output.shift() + (output.length ? '.' + output.join('') : '')
        value = value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '')
        if (value === '-.') {
            value = '-'
        }
        if (value === '.') {
            value = ''
        }
        setGrams(value)
        setOunces((grams * 0.0321507466).toFixed(10).replace(/\.?0+$/, '') + ' t oz')
        if (isNaN(value)) {
            if (value === '-') {
                setMessage(`0 grammaa on Troy-unsseina 0 t oz`)
                setOunces('0 t z')
            } else {
                setErrorMessage('Virheellinen luku. Syötä ainoastaan numeroita.')
                setMessage('')
            }
        } else {
            if (value.length > 0) {
                setMessage(`${grams.replace('.', ',')} grammaa on Troy-unsseina ${ounces.replace('.', ',')}`)
                setErrorMessage('')
            } else {
                setMessage('')
                setOunces('')
            }
        }
    }

    return (
        <div>
            <Breadcrumb>
                <LinkContainer to="../">
                    <Breadcrumb.Item>
                        Alkuun
                    </Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="./">
                    <Breadcrumb.Item>
                        Yksikkömuuntimet
                    </Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>
                    Gramma-Troy-Unssi-muunnin
                </Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div style={{ maxWidth: '90%' }}>
                    <h2>
                    Gramma-Troy-Unssi-muunnin
                    </h2>
                </div>
                <div className='calculator'>
                    <p>Tällä muuntimella voit muuntaa grammat Troy-unsseiksi (t oz).</p>
                    <p>Troy-unssi on nykyään erityisesti jalometallien kuten kullan ja hopean mittaamiseen käytettävä massan yksikkö.
                        Troy-unssista käytetään usein myös nimitystä kultaunssi tai hopeaunssi.
                        Troy-unssi vastaa 31,1034768 grammaa ja 12 troy-unssia = 1 troy-pauna tai troy-naula.
                    </p>
                    <p>Muunna grammat Troy-unsseiksi helposti syöttämällä muuntimeen grammojen määrä ja muunnin muuttaa grammat Troy-unsseiksi.</p>
                    <div>
                        <form>
                            <p>
                                <b>Syötä grammojen määrä (g):</b> <input value={grams.replace('.', ',')} onChange={({ target }) => handleChange(target.value)}>
                                </input>
                            </p>
                        </form>
                        <Notification message={message} result={ounces.replace('.', ',')} errorMessage={errorMessage} />
                        {(ounces && grams) && <p><b>{(grams === '-') ? 0 : grams.replace('.', ',')} g x 0,0321507466 = <span style={{ fontSize: '1.2em', color: '#155724' }}>{(((grams === '-') ? 0 : grams) * 0.0321507466).toFixed(10).replace(/\.?0+$/, '').replace('.', ',')} t oz</span></b></p>}
                        <p>Katso myös <Link to="./troy-unssi-gramma-muunnin">Troy-unssi-Gramma-muunnin</Link>.</p>
                    </div>
                </div>
            </div>
            <hr />
            <h4>Grammojen muuttaminen Troy-unsseiksi</h4>
            <p>Grammojen muuttaminen Troy-unsseiksi onnistuu yksinkertaisella kaavalla: <b>Troy-unssit = grammat x 0,032151</b>.
                <br />
                <br />
                Esimerkkejä Gramma-Troy-unssi muunnoksista:
            </p>
            <ul>
                <li>100 g = 100 x 0,0321507466 = 3,2151 t oz</li>
                <li>150 g = 150 x 0,0321507466 = 4,8226 t oz</li>
                <li>250 g = 250 x 0,0321507466 = 8,0377 t oz</li>
                <li>1000 g = 1000 x 0,0321507466 = 32,1507 t oz</li>
            </ul>
        </div>
    )
}

export default GramToTroyOunce