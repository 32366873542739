import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThermometer, faExchange, faShoePrints, faSortAmountDown, faWeight, faCar } from '@fortawesome/free-solid-svg-icons'

const Sidebar = () => {
    return (
        <div className='sidebarStyle'>
            <h5>Suosituimmat sivut</h5>
            <ListGroup>
                <ListGroup.Item>
                    <Link to='/yksikkomuuntimet/desimaali-binaari-muunnin'><FontAwesomeIcon icon={faExchange} className="iconsStyle" />Desimaaliluku - binäärilukumuunnin</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to='/hyoty/merkki-ja-sanalaskuri'><FontAwesomeIcon icon={faSortAmountDown} className="iconsStyle" />Sanalaskuri</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to='/hyoty/sahkoauto-polttomoottori-hintavertailu'><FontAwesomeIcon icon={faCar} className="iconsStyle" />Sähköauto - polttomoottoriauto hintavertailu</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to='/terveys/painoindeksilaskuri'><FontAwesomeIcon icon={faWeight} className="iconsStyle" />Painoindeksilaskuri</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to='/yksikkomuuntimet/kengankoko-muunnin'><FontAwesomeIcon icon={faShoePrints} className="iconsStyle" />Kengän koko-muunnin</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to='/yksikkomuuntimet/fahrenheit-celsius-muunnin'><FontAwesomeIcon icon={faThermometer} className="iconsStyle" />Fahrenheit - Celsius-muunnin</Link>
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default Sidebar