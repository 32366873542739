import React from 'react'
import { Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from "react-router-bootstrap"
import logo from '.././logo.svg'
import finland from '../assets/flags/finland.png'
import england from '../assets/flags/united-kingdom.png'

const Header = () => {
  return (
    <Navbar bg="dark" variant="dark" className="p-3">
      <LinkContainer to="/">
        <Navbar.Brand href="#home">
          <img
            alt="Laskimet ja muuntimet"
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          <b>Laske & Muunna</b>
        </Navbar.Brand>
      </LinkContainer>
      <NavDropdown title={<><img className='dropdown-menu-item' alt="Suomi / Finnish" src={finland} width="20" height="20" /><span className='dropdown-menu-item'> Suomi</span></>} id="collasible-nav-dropdown" className="ml-auto dropleft">
        <NavDropdown.Item href="#" active>
          <img alt="Suomi / Finnish - laske ja muunna" src={finland} width="20" height="20" />
          {' '}
          Suomi
        </NavDropdown.Item>
        <NavDropdown.Item href="https://calculatenconvert.com">
          <img alt="English - calculate and convert" src={england} width="20" height="20" />
          {' '}
          English
        </NavDropdown.Item>
      </NavDropdown>
    </Navbar>
  )
}

export default Header