import React, { useState, useEffect } from 'react'
import { Breadcrumb, InputGroup, FormControl, Form, Dropdown, Row, Col } from 'react-bootstrap'
import { LinkContainer } from "react-router-bootstrap"
import Notification from '../Notification'

const WeightCon = () => {
    const [values, setValues] = useState({ value: 0, grams: 0, unit: 'g', unitTo: 'kg', message: '', errorMessage: '' })

    useEffect(() => {
        document.title = 'Painomuunnin / massamuunnin - Laske & Muunna'
    })

    const unitsFrom = {
        1: { unit: 'u', name: 'Gramma', strFromTo: 'atomimassayksikköä (u)', factor: 1 },
        2: { unit: 'pg', name: 'Kilogramma', strFromTo: 'pikogrammaa (pg)', factor: 1000 },
        3: { unit: 'ng', name: 'Tonnin', strFromTo: 'nanogrammaa (ng)', factor: 1000000 },
        4: { unit: 'ug', name: 'Tonnin', strFromTo: 'mikrogrammaa (ug)', factor: 1000000000 },
        5: { unit: 'mg', name: 'Tonnin', strFromTo: 'milligrammaa (mg)', factor: 1000000000000 },
        6: { unit: 'g', name: 'Tonnin', strFromTo: 'grammaa (g)', factor: 1000000000000000 },
        7: { unit: 'kg', name: 'Tonnin', strFromTo: 'kilogrammaa (kg)', factor: 1000000000000000000 },
        8: { unit: 't', name: 'Tonnin', strFromTo: 'tonnia (t)', factor: 1000000000000000000000 }

    }

    const onChangeSizeFrom = (e) => {
        const value = e.target.value

        switch (value) {
            case 1:
                setValues({ ...values, unit: 'g' })
                break
            case 2:
                setValues({ ...values, unit: 'kg' })
        }
    }

        const onChangeUnitFrom = (e) => {
            setValues({ ...values, unit: e.target.value })
        }

        const onChangeUnitTo = (e) => {
        }

        const handleChange = (obj) => {
            obj.value = obj.value.toString().replace(/,/g, '.')
            obj.value = obj.value.replace(/,/g, '.')
            obj.value = obj.value.replace(/^(-)|[^0-9.,]+/g, '$1')
            obj.value = obj.value.replace(/.*?(-?)(\d*\.\d+)[^\d.]*/, '$1$2')
            const output = obj.value.split('.')
            obj.value = output.shift() + (output.length ? '.' + output.join('') : '')
            obj.value = obj.value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '')
            if (obj.value === '-') {
                obj.value = '-'
            }
            if (obj.value === '.') {
                obj.value = ''
            }
            switch (obj.unit) {
                case 'mm': isNaN(obj.value) ? setValues({ ...values, millimeter: obj.value, centimeter: 0, inch: 0, kilometer: 0, meter: 0, errorMessage: 'Virheellinen luku' }) : setValues({ ...values, millimeter: obj.value, centimeter: obj.value / 10, inch: obj.value * 0.0393700787, kilometer: obj.value / 1000000, meter: obj.value / 1000, mail: obj.value * 0.00000062137, errorMessage: '' })
                    break
                case 'cm': isNaN(obj.value) ? setValues({ ...values, centimeter: obj.value, inch: 0, kilometer: 0, meter: 0, millimeter: 0, errorMessage: 'Virheellinen luku' }) : setValues({ ...values, millimeter: obj.value * 10, centimeter: obj.value, inch: obj.value * 0.393700787, kilometer: obj.value / 100000, meter: obj.value / 100, mail: obj.value * 0.0000062137, errorMessage: '' })
                    break
                case 'm': isNaN(obj.value) ? setValues({ ...values, meter: obj.value, inch: 0, kilometer: 0, millimeter: 0, centimeter: 0, errorMessage: 'Virheellinen luku' }) : setValues({ ...values, meter: obj.value, millimeter: obj.value * 1000, centimeter: obj.value * 100, inch: obj.value * 100 * 0.393700787, kilometer: obj.value / 1000, mail: obj.value * 0.00062137, errorMessage: '' })
                    break
                case 'km': isNaN(obj.value) ? setValues({ ...values, kilometer: obj.value, inch: 0, meter: 0, millimeter: 0, centimeter: 0, errorMessage: 'Virheellinen luku' }) : setValues({ ...values, kilometer: obj.value, centimeter: obj.value * 100000, inch: obj.value * 100000 * 0.393700787, meter: obj.value * 1000, millimeter: obj.value * 1000000, mail: obj.value * 0.62137, errorMessage: '' })
                    break
                case 'inch': isNaN(obj.value) ? setValues({ ...values, inch: obj.value, meter: 0, millimeter: 0, centimeter: 0, kilometer: 0, errorMessage: 'Virheellinen luku' }) : setValues({ ...values, inch: obj.value, centimeter: obj.value * 2.54, meter: obj.value * 2.54 / 100, kilometer: obj.value * 2.54 / 100000, millimeter: obj.value * 25.4, mail: obj.value * 0.000015783, errorMessage: '' })
                    break
                case 'mail': isNaN(obj.value) ? setValues({ ...values, mail: obj.value, meter: 0, millimeter: 0, centimeter: 0, kilometer: 0, errorMessage: 'Virheellinen luku' }) : setValues({ ...values, mail: obj.value, centimeter: obj.value * 1.609344 * 100000, meter: obj.value * 1.609344 * 1000, kilometer: obj.value * 1.609344, millimeter: obj.value * 1.609344 * 1000000, inch: obj.value * 63360.23622, errorMessage: '' })
                    break
                default: setValues({ millimeter: 10, centimeter: 1, inch: 0.393700787, meter: 0.01, kilometer: 0.00001, message: '', errorMessage: '' })

            }
        }

        return (
            <div>
                <Breadcrumb>
                    <LinkContainer to="../">
                        <Breadcrumb.Item>
                            Alkuun
                        </Breadcrumb.Item>
                    </LinkContainer>
                    <LinkContainer to="./">
                        <Breadcrumb.Item>
                            Yksikkömuuntimet
                        </Breadcrumb.Item>
                    </LinkContainer>
                    <Breadcrumb.Item active>
                        Painomuunnin / massamuunnin
                    </Breadcrumb.Item>
                </Breadcrumb>
                <h2>
                    Painomuunnin / massamuunnin
                </h2>
                <div className="calculator">
                    <p>
                        Syötä arvo johonkin alla olevan laskurin kentistä. Laskuri näyttää muiden yksiköiden muunnetut arvot. Voit muuntaa muuntimella esimerkiksi senttimetrit tuumiksi, millimetrit kilometreiksi, mailit kilometreiksi tai toisinpäin.
                    </p>
                    <hr />
                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Label>Muunnettava luku</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{values.unit}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="fromUnit">
                                <Form.Label>Yksiköstä</Form.Label>
                                <Form.Control as="select" value={values.unitFrom} onChange={onChangeUnitFrom}>
                                    <optgroup label="Metriset">
                                        <option value='u'>Atomimassayksikkö (u)</option>
                                        <option value='pg'>Pikogramma (pg)</option>
                                        <option value='ng'>Nanogramma (ng)</option>
                                        <option value='µg'>Mikrogramma (µg)</option>
                                        <option value='mg'>Milligramma (mg)</option>
                                        <option value='cg'>Senttigramma (cg)</option>
                                        <option value='dg'>Desigramma (dg)</option>
                                        <option value=''>Karaatti</option>
                                        <option value='g' selected>Gramma (g)</option>
                                        <option value='dag'>Dekagramma (dag)</option>
                                        <option value={11}>Hehtogramma (hg)</option>
                                        <option value={12}>Newton, maan pinnalla (N)</option>
                                        <option value={13}>Kilogramma (kg)</option>
                                        <option value={14}>Centner</option>
                                        <option value={15}>Kilonewton, maan pinnalla (kN)</option>
                                        <option value={16}>Tonni (t)</option>
                                        <option value={17}>Kilotonni (kt)</option>
                                    </optgroup>
                                    <optgroup label="Avoirdupois (USAlaiset ja Brittiläiset)">
                                        <option value={18}>Graani, graini (gr)</option>
                                        <option value={19}>Dram (dr)</option>
                                        <option value={20}>Unssi (oz)</option>
                                        <option value={21}>Pauna, naula (lb, lbs)</option>
                                        <option value={22}>Stone (st)</option>
                                        <option value={23}>Short quarter, US (qtr)</option>
                                        <option value={24}>Long quarter, UK (qtr)</option>
                                        <option value={25}>Short hundredweight, US (cwt)</option>
                                        <option value={26}>Long hundredweight, UK (cwt)</option>
                                        <option value={27}>Short ton, US (t)</option>
                                    </optgroup>
                                    <optgroup label="Troy">
                                        <option value={28}>Doite</option>
                                        <option value={29}>Mite</option>
                                        <option value={30}>Graani, graini (gr)</option>
                                        <option value={31}>Karaatti</option>
                                        <option value={32}>Pennyweight</option>
                                        <option value={33}>Unssi (ozt)</option>
                                        <option value={34}>Pauna, naula (lb, lbs)</option>
                                    </optgroup>
                                    <optgroup label="Apothecaries (Apteekkarit)">
                                        <option value={35}>Graani, graini (gr)</option>
                                        <option value={36}>Scruple</option>
                                        <option value={37}>Dram (dr)</option>
                                        <option value={38}>Unssi (oz)</option>
                                        <option value={39}>Pauna, naula (lb, lbs)</option>
                                    </optgroup>
                                    <optgroup label="Muut">
                                        <option value={40}>Planckin massa</option>
                                        <option value={41}>Maan massa</option>
                                        <option value={42}>Auringon massa</option>
                                    </optgroup>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Label>Yksikköön</Form.Label>
                            <Form.Group controlId="toUnit">
                                <Form.Control as="select" value={values.unitTo} onChange={onChangeUnitTo}>
                                    <optgroup label="Metriset">
                                        <option value={1}>Atomimassayksikkö (u)</option>
                                        <option value={2}>Pikogramma (pg)</option>
                                        <option value={3}>Nanogramma (ng)</option>
                                        <option value={4}>Mikrogramma (µg)</option>
                                        <option value={5}>Milligramma (mg)</option>
                                        <option value={6}>Senttigramma (cg)</option>
                                        <option value={7}>Desigramma (dg)</option>
                                        <option value={8}>Karaatti</option>
                                        <option value={9}>Gramma (g)</option>
                                        <option value={10}>Dekagramma (dag)</option>
                                        <option value={11}>Hehtogramma (hg)</option>
                                        <option value={12}>Newton, maan pinnalla (N)</option>
                                        <option value={13} selected>Kilogramma (kg)</option>
                                        <option value={14}>Centner</option>
                                        <option value={15}>Kilonewton, maan pinnalla (kN)</option>
                                        <option value={16}>Tonni (t)</option>
                                        <option value={17}>Kilotonni (kt)</option>
                                    </optgroup>
                                    <optgroup label="Avoirdupois (USAlaiset ja Brittiläiset)">
                                        <option value={18}>Graani, graini (gr)</option>
                                        <option value={19}>Dram (dr)</option>
                                        <option value={20}>Unssi (oz)</option>
                                        <option value={21}>Pauna, naula (lb, lbs)</option>
                                        <option value={22}>Stone (st)</option>
                                        <option value={23}>Short quarter, US (qtr)</option>
                                        <option value={24}>Long quarter, UK (qtr)</option>
                                        <option value={25}>Short hundredweight, US (cwt)</option>
                                        <option value={26}>Long hundredweight, UK (cwt)</option>
                                        <option value={27}>Short ton, US (t)</option>
                                    </optgroup>
                                    <optgroup label="Troy">
                                        <option value={28}>Doite</option>
                                        <option value={29}>Mite</option>
                                        <option value={30}>Graani, graini (gr)</option>
                                        <option value={31}>Karaatti</option>
                                        <option value={32}>Pennyweight</option>
                                        <option value={33}>Unssi (ozt)</option>
                                        <option value={34}>Pauna, naula (lb, lbs)</option>
                                    </optgroup>
                                    <optgroup label="Apothecaries (Apteekkarit)">
                                        <option value={35}>Graani, graini (gr)</option>
                                        <option value={36}>Scruple</option>
                                        <option value={37}>Dram (dr)</option>
                                        <option value={38}>Unssi (oz)</option>
                                        <option value={39}>Pauna, naula (lb, lbs)</option>
                                    </optgroup>
                                    <optgroup label="Muut">
                                        <option value={40}>Planckin massa</option>
                                        <option value={41}>Maan massa</option>
                                        <option value={42}>Auringon massa</option>
                                    </optgroup>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Notification message={values.message} errorMessage={values.errorMessage} />
                </div>
                <h4>Esimerkkejä pituusyksiköiden muuntamisesta toiseen</h4>
                <p>Pituuden SI-järjestelmän mukainen perusyksikkö on metri. Esimerkiksi metrien muuttaminen kilometreiksi tai senttimetreiksi on monelle luonnostaan helppoa omassa päässä ilman laskinta. Kuitenkin metrien muuttaminen esimerkiksi tuumiksi tai maileiksi voi olla haastavampaa. Alla muutamia yleisimpiä kaavoja pituusyksiköiden muuntamisesta toiseen</p>
                <h5>Mailit kilometreiksi</h5>
                <p>Yksi maili on 1,609 km. Yksi kilometri on 0,621 mailia.</p>
                <p>Mailit muutetaan kilometreiksi kaavalla: kilometrit * 1,609</p>
                <p>
                    1 mi = 1 * 1,609 = 1,61 km
                    <br />
                    2 mi = 2 * 1,609 = 3,22 km
                    <br />
                    5 mi = 5 * 1,609 = 8,05 km
                    <br />
                    157 mi = 157 * 1,609 = 252,67 km
                </p>
                <h5>Kilometrit maileiksi</h5>
                <p>Kilometrit muutetaan maileiksi kaavalla: kilometrit * 0,621</p>
                <p>
                    1 km = 1 * 0,621 = 0,621 mi
                    <br />
                    2 km = 2 * 0,621 = 1,243 mi
                    <br />
                    5 km = 5 * 0,621 = 3,107 mi
                    <br />
                    157 km = 157 * 0,621 = 97,555 mi
                </p>
                <h5>Tuumat metreiksi</h5>
                <p>Tuumat muutetaan metreiksi kaavalla: tuumat * 0,0254</p>
                <p>
                    1 in = 1 * 0,0254 = 0,0254 m
                    <br />
                    2 in = 2 * 0,0254 = 0,051 m
                    <br />
                    5 in = 5 * 0,0254 = 0,127 m
                    <br />
                    157 in = 1 * 0,0254 = 3,988 m
                </p>
                <h5>Metrit tuumiksi</h5>
                <p>Metrit muutetaan tuumiksi kaavalla: metrit * 39,37</p>
                <p>   1 m = 1 * 39,37 = 39,37 in
                    <br />
                    2 m = 2 * 39,37 = 78,74 in
                    <br />
                    5 m = 5 * 39,37 = 196,85 in
                    <br />
                    157 m = 157 * 39,37 = 6181,1 in
                </p>
            </div>
        )
    }

    export default WeightCon