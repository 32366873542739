import React, { useState, useEffect } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from "react-router-dom"
import Notification from '../Notification'
import { LinkContainer } from 'react-router-bootstrap'

const GramToOunce = () => {
    const [ounces, setOunces] = useState('3.52740')
    const [grams, setGrams] = useState('100')
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        handleChange(grams)
        document.title = 'Gramma-Unssi-muunnin - Laske & Muunna'
    })

    const handleChange = (value) => {
        value = value.toString()
        value = value.replace(/,/g, '.')
        value = value.replace(/^(-)|[^0-9.,]+/g, '$1')
        value = value.replace(/.*?(-?)(\d*\.\d+)[^\d.]*/, '$1$2')
        const output = value.split('.')
        value = output.shift() + (output.length ? '.' + output.join('') : '')
        value = value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '')
        if (value === '-.') {
            value = '-'
        }
        if (value === '.') {
            value = ''
        }
        setGrams(value)
        setOunces((grams * 0.03527396195).toFixed(5).replace(/\.?0+$/, '') + ' oz')
        if (isNaN(value)) {
            if (value === '-') {
                setMessage(`0 grammaa on unsseina 0 oz`)
                setOunces('0 oz')
            } else {
                setErrorMessage('Virheellinen luku. Syötä ainoastaan numeroita.')
                setMessage('')
            }
        } else {
            if (value.length > 0) {
                setMessage(`${grams.replace('.', ',')} grammaa on unsseina ${ounces.replace('.', ',')}`)
                setErrorMessage('')
            } else {
                setMessage('')
                setErrorMessage('')
            }
        }

    }

    return (
        <div>
            <Breadcrumb>
                <LinkContainer to="../">
                    <Breadcrumb.Item>
                        Alkuun
                    </Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="./">
                    <Breadcrumb.Item>
                        Yksikkömuuntimet
                    </Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>
                    Gramma-Unssi-muunnin
                </Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div style={{ maxWidth: '90%' }}>
                    <h2>
                    Gramma-Unssi-muunnin
                    </h2>
                </div>
                <div className='calculator'>
                    <p>Tällä muuntimella voit muuntaa grammat unsseiksi.</p>
                    <p>Kansainvälisesti käytetty Avoirdupois-unssi (oz) on vanha painoyksikkö, joka vastaa 28,349523125 grammaa. Unssia käytetään painoyksikkönä edelleen erityisesti brittiläisiä yksiköitä käyttävissä maissa. Myös Yhdysvalloissa käytössä oleva yksikköjärjestelmä (U.S. Customary System) perustuu suurelta osin brittiläiseen järjestelmään.</p>
                    <p>Muunna grammat unsseiksi helposti syöttämällä muuntimeen gramma määrä ja muunnin muuttaa grammat unsseiksi.</p>
                    <div>
                        <form>
                            <p>
                                <b>Syötä grammojen määrä (g):</b> <input value={grams.replace('.', ',')} onChange={({ target }) => handleChange(target.value)}>
                                </input>
                            </p>
                        </form>
                        <Notification message={message} result={ounces.replace('.', ',')} errorMessage={errorMessage} />
                        {(ounces && grams) && <p><b>{grams === '-' ? 0 : grams.replace('.', ',')} g x 0,03527396195 = <span style={{ fontSize: '1.2em', color: '#155724' }}>{((grams === '-' ? 0 : grams) * 0.03527396195).toFixed(5).replace(/\.?0+$/, '').replace('.', ',')} oz</span></b></p>}
                        <p>Katso myös <Link to="./unssi-gramma-muunnin">Unssi-Gramma-muunnin</Link>.</p>
                    </div>
                </div>
            </div>
            <hr />
            <h4>Grammojen muuttaminen unsseiksi</h4>
            <p>Grammojen muuttaminen unsseiksi onnistuu yksinkertaisella kaavalla: <b>Unssit = grammat x 0,03527396195</b>.
                <br />
                <br />
                Esimerkkejä Gramma-Unssi muunnoksista:
            </p>
            <ul>
                <li>100 g = 100 x 0,03527396195 = 3,53 oz</li>
                <li>150 g = 150 x 0,03527396195 = 5,29 oz</li>
                <li>250 g = 250 x 0,03527396195 = 8,82 oz</li>
                <li>1000 g = 1000 x 0,03527396195 = 35,27 oz</li>
            </ul>
        </div>
    )
}

export default GramToOunce